import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './index.module.scss'
import Header from '@/components_v2/Header'
import { goBack } from "@/utils/webViewUtils"
import SendWithTarget from '../components/SendWithTarget'
import { getOwnProducts } from '@/services/product'
import { useOnceRequest} from '@/hooks'
import { sendGroupMessage } from '@/services/message'
import { Toast } from 'antd-mobile'
import ScrollView from '@/components_v2/ScrollView'

function GroupMessageWithProduct() {
  const paging = useRef({ page: 1, noData: false })
  const [products, setProducts] = useState([])
  const [value, setValue] = useState()
  const [__getProducts, isPending] = useOnceRequest(getOwnProducts)


  const getProducts = useCallback(() => {
    if(paging.current.noData || isPending()){
      return
    }

    Toast.loading('数据加载中', 0, null, true)
    const { page } = paging.current
    __getProducts({ page, limit: 20, status: 1}).then(res => {
      Toast.hide()
      if(res.code !== 1000) {
        Toast.info(res.msg, 2, null, false)
        return
      }

      let { data, total_num } = res.data

      if(page > 1){
        data = [...products, ...data]
      }

      if(total_num >= data.length) {
        paging.current.noData = true
      }

      setProducts(data)
    })
  }, [products])

  useEffect(() => {
    getProducts()
  }, [])

  const scrollRef = useRef()
  const onScroll = useCallback(({ target }) => {
    const { clientHeight, scrollHeight, scrollTop } = target
    if(scrollHeight - clientHeight <= scrollTop){
      paging.current.page++
      getProducts()
    }
  }, [getProducts])

  const [visible, setVisible] = useState(false)
  const next = useCallback(() => {
    if(!value){
      Toast.info('请选择需要群发的产品', 2, null, false)
      return
    }

    setVisible(true)
  }, [value])
  const send = useCallback(({ type, value: val, title }) => ({
    promise: sendGroupMessage({
      type: 16,
      body: value,
      mass_type: type,
      mass_value: val,
      extra: title
    }),
    success: res => goBack()
  }), [value])

  return (
    <div className={styles.container}>
      <Header title="选择产品" onGoBack={goBack} />
      <div className={styles.title}>请选择想要推荐的产品（单选）</div>
      <ScrollView className={styles.content} ref={scrollRef} onScroll={onScroll}>
        <div className={styles.products}>
          {
            products.map(({ id, productName}, i) => (
              <ProductItem key={i} title={productName} value={id === value} onClick={() => setValue(id)} />
            ))
          }
        </div>
      </ScrollView>
      <div className={styles['next-step_wrapper']}>
        <button className={styles['next-step']} onClick={next}>下一步</button>
      </div>
      <SendWithTarget visible={visible} type="产品" onGoBack={() => setVisible(false)} onSend={send} />
    </div>
  )
}

export default GroupMessageWithProduct

function ProductItem(props) {
  const { value, title, ...restProps } = props

  const checkbox = useMemo(() => {
    if(value) {
      return require('@/images/groupMessage/checkbox_active.png')
    } else {
      return require('@/images/groupMessage/checkbox.png')
    }
  }, [value])

  return (
    <div {...restProps} className={styles.product}>
      <div className={`${styles.product_item} ${value ? styles.active : ''} single-line_text`}>{title}</div>
      <img className={styles.product_icon} src={checkbox} alt="" />
    </div>
  )
}