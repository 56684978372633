import axios from "axios"
import qs from "qs"
import { getUserToken } from "../common/connectAndroid"

let FETCH_URL = ""
if (process.env.NODE_ENV === "production") {
  FETCH_URL = "https://api.yaoxiebang.com.cn"
} else {
  // FETCH_URL = "https://api.yaoxiebang.cn"
  FETCH_URL = "https://api.yaoxiebang.com.cn"
}
axios.defaults.timeout = 15000
axios.defaults.baseURL = FETCH_URL
axios.interceptors.request.use(config => {
  if (config.method == "get") {
    if (!config["params"]) {
      Object.assign(config, {
        params: {
          flag: new Date().getTime()
        }
      })
    } else {
      Object.assign(config.params, {
        flag: new Date().getTime()
      })
    }
  }
  // if(process.env.NODE_ENV === 'development'){
  //   config.headers.Authorization = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOjM3NTMsInR5cGUiOjAsImV4cCI6IjEiLCJ0aW1lIjoxNTU1NzM0NDMwfQ.igdB7VH3NOLjjmuGS9ba2JEbRLf4tZpvPUNgzsWUkHw'
  //   config.headers.platform = 2
  // }else{
  config.headers.Authorization = getUserToken()
  config.headers.Source = 'h5'
  // }
  return config
})
  ; (function (win) {
    var util = {
      get: function (url, params = {}) {
        return axios({
          method: "get",
          url,
          params
        })
      },
      post: function (url, body = {}, headers = { platform: 'h5' }) {
        const data = qs.stringify(body)
        return axios({
          method: "post",
          url,
          data,
          headers
        })
      },
      put: function (url, body = {}) {
        const data = qs.stringify(body)
        return axios({
          method: "put",
          url,
          data
        })
      },
      delete: function (url) {
        return axios({
          method: "delete",
          url
        })
      }
    }

    win.util = util
  })(window)
