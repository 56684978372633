import ModalMask from '@/components_v2/Modal/Mask'
import { census } from '@/services/common'
import { goBack, postMessage } from '@/utils/webViewUtils'
import React, { useCallback, useImperativeHandle, useState } from 'react'
import styles from './index.module.scss'

function VipModal(props, ref) {
  const [visible, setVisible] = useState(false)
  const [vipLevel, setVipLevel] = useState(1)

  const open = useCallback(level => {
    setVipLevel(level)
    setVisible(true)
  }, [])

  const onClose = useCallback(() => {
    goBack()
    setVisible(false)
  }, [])

  const openVip = useCallback(() => {
    census({ type: 'open_vip', channel: 'groupMessage' })
    onClose()
    postMessage({ type: 'router', payload: { route: 'vip/open' } })
  }, [])

  useImperativeHandle(ref, () => ({ open }), [open])

  if(!visible) return null

  return (
    <ModalMask onClose={onClose}>
      <div className={styles.modal}>
        <div className={styles.modal_container} onClick={e => e.stopPropagation()}>
          <div className={styles.modal_content}>
            <div className={styles.modal_text}>
              <span>今日消息群发次数已达上限</span>
              { vipLevel <= 1 && (
                <>
                  <br />
                  <span className={styles.modal_text_vip}>开通企业VIP</span>
                  <span>每日可群发5次消息</span>
                </>
              ) }
            </div>
            { vipLevel <= 1 && <img className={styles.modal_open_vip_btn} src={require('@/images/buy/open_btn.png')} onClick={openVip} /> }
          </div>
        </div>
        <img className={styles.modal_close} src={require('@/images/icon/close_gold.png')} />
      </div>
    </ModalMask>
  )
}

export default React.forwardRef(VipModal)
