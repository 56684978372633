import React from "react"
import { router } from 'dva'
import Loadable from './loadable'

const { Router, Route } = router

import VisitorData from '@/pages_v2/VisitorData'
import GroupMessage from '@/pages_v2/GroupMessage'
import { useDvaTokenByURL } from "@/hooks"

//首页
const Home = Loadable(() => import("../pages/home"))

// 产品发布
const Release = Loadable(() => import("../pages/drug/release"))

// 产品发布类型选择
const TypeChild = Loadable(() => import("../pages/drug/release/typeChild"))

// 产品发布信息
const DrugInfo = Loadable(() => import("../pages/drug/release/drugInfo"))

// 产品列表
const DrugList = Loadable(() => import("../pages/drug/drugList"))

// 产品详细
const DrugDetails = Loadable(() => import("../pages/drug/drugDetails"))

// 产品的个人主页
const DetailMine = Loadable(() => import("../pages/drug/drugDetails/detailMine"))

// 产品编辑
const DrugEdit = Loadable(() => import("../pages/drug/drugDetails/drugEdit"))
// 产品编辑详情
const DrugEditInfo = Loadable(() => import("../pages/drug/drugDetails/drugEdit/drugInfo"))
// 代理列表
const AgentList = Loadable(() => import("../pages/agent/agentList"))
// 代理发布信息
const AgentInfo = Loadable(() => import("../pages/agent/release"))

// 代理详细
const AgentDetails = Loadable(() => import("../pages/agent/agentDetails"))

// 代理的个人主页
const AgentDetailMine = Loadable(() => import("../pages/agent/agentDetails/detailMine"))

// 代理编辑
const AgentEdit = Loadable(() => import("../pages/agent/agentDetails/agentEdit"))

// 发布成功
const ReleaseSuccess = Loadable(() => import("../components/releaseSuccess"))

// 个人资料
const UserInfo = Loadable(() => import("../pages/userInfo"))

// 我的代理
const MineAgent = Loadable(() => import("../pages/mine/mineAgent"))
// 我的产品
const MineProduct = Loadable(() => import("../pages/mine/mineProduct"))

// 分享海报
const SharePoste = Loadable(() => import("../pages/mine/share"))

//海报详情
const PosterDetail = Loadable(()=> import('../pages/mine/share/posterDetail'))

//我的访客
const Visitor= Loadable(() => import("../pages/mine/visitor"))

//我的关注
const Follow = Loadable(() => import("../pages/mine/follow"))

//我的粉丝
const Fans = Loadable(() => import("../pages/mine/fans"))
//活动组type
const Activity = Loadable(() => import('../pages/activity'))

// 绑定手几号
const BindPhoneNumber = Loadable(() => import('../pages/bindPhone'))

// weChatDetail
const weChatDetail = Loadable(() => import('../pages/weChatDetail?b=3'))

//h5Login
const h5Login = Loadable(() => import('../pages/h5Login'))

//yxb_ad
const yxb_ad = Loadable(() => import('../pages/yxb_ad'))

//yxb_yyy
const yxb_yyy = Loadable(() => import('../pages/yxb_yyy'))

//yxb_saleData
const yxb_saleData = Loadable(() => import('../pages/yxb_saleData'))

//yxb_banner_ky
const yxb_banner_ky = Loadable(() => import('../pages/yxb_banner_ky'))

//yxb_banner_yq
const yxb_banner_yq = Loadable(() => import('../pages/yxb_banner_yq'))

//inviteRecord
const inviteRecord = Loadable(() => import('../pages/inviteRecord'))

// app 入口
const Entry = Loadable(() => import('../pages/converge/entry'))

//学习中心
const ConvergeCourse = Loadable(() => import("../pages/converge/course"))

const ConvergeCourseDetail = Loadable(()=> import("../pages/converge/course/detail"))
// 首页
const ConvergeHome  = Loadable(() => import("../pages/converge/home"))
// 我的
const ConvergeMine = Loadable(() => import('../pages/converge/mine'))

// 积分提现
const IntegralEmbodiment = Loadable(()=> import('../pages/converge/mine/integralEmbodiment'))

// 实名认证
const Authentication = Loadable(()=> import('../pages/converge/mine/authentication'))

//客户学习记录
const Record = Loadable(()=> import('../pages/converge/mine/record'))

// 积分分享
const Allot  = Loadable(() => import('../pages/converge/allot'))

// 分享名单
const Roster = Loadable(()=> import('../pages/converge/allot/roster'))

// 绑定手机号
const BindPhone = Loadable(()=> import('../pages/converge/bindPhone'))

// 活动注册账号
const ActivityRegister = Loadable(()=> import('../pages/activity_register'))

// 问答详情
const Question = Loadable(()=> import('../pages_v2/community/question'))

// VIP
const VIP = Loadable(()=> import('../pages_v2/vip'))

// 个人主页
const PersonalPage = Loadable(() => import("../pages_v2/PersonalPage"))
const Activities = Loadable(() => import("../pages_v2/Activities"))
const BuyVip = Loadable(() => import('@/pages_v2/BuyVip'))
const Popularize = Loadable(() => import('@/pages_v2/Popularize'))

// 测试
const Test = Loadable(() => import('@/pages_v2/Test'))

export default (({ history }) => {
  useDvaTokenByURL()
  return (
    <Router history={history}>
      <>
        <Route exact path="/" component={Home} />
        <Route exact path="/drug/release" component={Release} />
        <Route exact path="/drug/release/typeChild" component={TypeChild} />
        <Route exact path="/drug/release/drugInfo" component={DrugInfo} />
        <Route exact path="/drug/drugList" component={DrugList} />
        <Route exact path="/drug/details/:id" component={DrugDetails} />
        <Route exact path="/drug/drugEdit" component={DrugEdit} />
        <Route exact path="/drug/drugEdit/info" component={DrugEditInfo} />
        <Route exact path="/drug/detailMine" component={DetailMine} />
        <Route exact path="/agent" component={AgentList} />
        <Route exact path="/agent/agentInfo" component={AgentInfo} />
        <Route exact path="/agent/details/:id" component={AgentDetails} />
        <Route exact path="/agent/agentEdit" component={AgentEdit} />
        <Route exact path="/agent/detailMine" component={AgentDetailMine} />
        <Route exact path="/userInfo" component={UserInfo} />
        <Route exact path="/release/success" component={ReleaseSuccess} />
        <Route exact path="/mine/mineAgent" component={MineAgent} />
        <Route exact path="/mine/mineProduct" component={MineProduct} />
        <Route exact path="/share/sharePoste/:type/:id" component={SharePoste} />
        <Route exact path="/poster/detail/:type/:id" component={PosterDetail}/>
        <Route exact path="/mine/fans" component={Fans} />
        <Route exact path="/mine/follow" component={Follow} />
        <Route exact path="/mine/visitor" component={Visitor} />

        <Route exact path='/home/activity' component={Activity}/>
        {/* :type = 1 产品 , :type = 2 代理*/}
        {/* :id = 产品id或者代理id */}
        <Route exact path='/bindPhoneNumber/:type/:id/:username/:openid/:platform' component={BindPhoneNumber}/>
        <Route exact path='/yxb_ad' component={yxb_ad} />
        <Route exact path='/yxb_saleData' component={yxb_saleData} />
        <Route exact path='/yxb_yyy' component={yxb_yyy} />
        <Route exact path='/yxb_banner_yq' component={yxb_banner_yq} />
        <Route exact path='/yxb_banner_ky' component={yxb_banner_ky} />
        <Route exact path='/inviteRecord/:type' component={inviteRecord} />
        <Route exact path='/h5Login/:type/:id' component={h5Login} />
        <Route exact path='/weChatDetail/:type/:id' component={weChatDetail} />
        {/* 药学汇 小程序*/}
        <Route exact path='/entry' component={Entry}/>
        <Route exact path='/converge/home' component={ConvergeHome}/>
        <Route exact path='/converge/course' component={ConvergeCourse} />
        <Route exact path='/converge/course/detail/:id' component={ConvergeCourseDetail} />
        <Route exact path='/converge/mine' component={ConvergeMine}/>
        <Route exact path='/converge/mine/integralEmbodiment' component={IntegralEmbodiment}/>
        <Route exact path='/converge/mine/record/:name' component={Record}/>
        <Route exact path='/converge/mine/authentication' component={Authentication}/>
        <Route exact path='/converge/bindPhone/:username/:openid/:platform' component={BindPhone}/>
        <Route exact path='/converge/allot/:id' component={Allot} />
        <Route exact path='/converge/allots/roster/:id' component={Roster} />
        <Route exact path='/activity-register' component={ActivityRegister} />
        {/* v2 */}
        <Route exact path='/question/:id' component={Question} />
        <Route exact path='/vip' component={VIP} />
        <Route exact path='/personal-page/:uid' component={PersonalPage} />
        <Route exact path='/activities/:id' component={Activities} />
        <VisitorData />
        {/* <GroupMessage /> */}
        <Route path='/group-message' component={GroupMessage} />
        <Route path='/buy-vip' component={BuyVip} />
        <Route path='/popularize' component={Popularize} />
        {
          /**
           * 测试
           */
        }
        <Route path='/test' component={Test} />
      </>
    </Router>
  )
})