import React from 'react'
import { dynamic, router } from 'dva'
import app from '@/app'

const { Route } = router

import List from './List'
import GroupMessageText from './Text'
import GroupMessagePicture from './Picture'
import GroupMessageProduct from './Product'

function VisitorData() {
  return (
    <>
      <Route exact path='/group-message/list' component={List} />
      <Route exact path='/group-message/sendText' component={GroupMessageText} />
      <Route exact path='/group-message/sendPicture' component={GroupMessagePicture} />
      <Route exact path='/group-message/sendProduct' component={GroupMessageProduct} />
    </>
  )
}

export default dynamic({
  app,
  models: () => [import('@/models/groupMessage')],
  component: () => Promise.resolve(VisitorData)
})