import OSS from 'ali-oss'
import { getDateOfArray, randomString } from '.';

let client

export async function upload(file) {
  if(!file) {
    return Promise.reject('文件不存在')
  }

  if(!client){
    client = new OSS({
      region: 'oss-cn-hangzhou',
      accessKeyId: 'LTAI4GJSzZShaner8hphiW29',
      accessKeySecret: 'fCqwCN2L5F81NXztyOnGNmfk6f5iUy',
      bucket: 'yxb-dev'
    })
  }
  
  const [year, month, date] = getDateOfArray()
  const dir = year + `${month}`.padStart(2, '0') + `${date}`.padStart(2, '0')
  const type = file.name.split('.').pop()
  const fileName = `${Date.now()}${randomString(6)}.${type}`

  return await client.put(`yxb-dev/${dir}/${fileName}`, file)
}