import React, { useCallback, useImperativeHandle, useLayoutEffect, useReducer, useRef } from 'react'
import { createStore } from 'redux'
import { isDef } from './utils'

function reducer(state = [], action) {
  const { payload, type } = action
  switch (type) {
    case 'add': {
      if (isDef(payload)) {
        const i = state.findIndex(val => val === payload)
        if (i >= 0) {
          state.splice(i, 1)
        } else {
          state.push(payload)
        }

        return [...state]
      }

      return state
    }
    case 'delete': {
      if (isDef(payload)) {
        const i = state.findIndex(val => val === payload)
        if (i >= 0) {
          state.splice(i, 1)
          return [...state]
        }
      }

      return state
    }
    case 'reset': {
      if (Array.isArray(payload)) {
        return payload
      }

      return []
    }
    default:
      return state
  }
}

export default function selector() {
  return Component =>
    React.forwardRef((props, ref) => {
      const store = useRef(createStore(reducer)).current
      const { dispatch, getState } = store
      const { onChange, max, mode = '', ...restProps } = props

      const __dispatch = useCallback(
        (type, payload) => {
          let prev = [...getState()]
          dispatch({ type, payload })
          let curr = [...getState()]
          if (max === 1) {
            prev = prev[0]
            curr = curr[0]
          }
          onChange && onChange(curr, prev)

          // console.group('dispatch', type, payload)
          // console.log('prev', prev)
          // console.log('curr', curr)
          // console.groupEnd()
        },
        [store, onChange, max]
      )

      const setValue = useCallback(
        payload => {
          if (max === 1) {
            const val = getState()
            if (mode === 'toggle') {
              if (val[0] === payload) {
                __dispatch('reset', [])
              } else {
                __dispatch('reset', [payload])
              }
            } else {
              // 不是toggle模式，一旦选中，必须选中一个，不可不选
              __dispatch('reset', [payload])
            }
          } else {
            __dispatch('add', payload)
          }
        },
        [max, mode, __dispatch, getState]
      )

      const resetValue = useCallback(
        payload => {
          __dispatch('reset', payload)
        },
        [__dispatch]
      )

      const deleteValue = useCallback(
        payload => {
          __dispatch('delete', payload)
        },
        [__dispatch]
      )

      const selectorProps = {
        value: max === 1 ? getState()[0] : getState(),
        setValue,
        resetValue,
        deleteValue
      }

      useImperativeHandle(ref, () => ({ ...selectorProps }))

      const [ignored, forceUpdate] = useReducer(x => x + 1, 0)
      useLayoutEffect(() => {
        const unsubscribe = store.subscribe(() => {
          forceUpdate()
        })

        return () => unsubscribe()
      }, [store])

      return <Component {...restProps} {...selectorProps} />
    })
}