import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import Header from '@/components_v2/Header'
import { goBack } from "@/utils/webViewUtils"
import { Toast } from 'antd-mobile'
import SendWithTarget from '../components/SendWithTarget'
import { sendGroupMessage } from '@/services/message'
import { upload } from '@/utils/aliOSS'
// import { upload } from '@/services/common'

function GroupMessageWithPicture() {
  const fileRef = useRef()
  const selectFile = useCallback(() => fileRef.current && fileRef.current.click(), [])
  const [file, setFile] = useState()
  const [imageSrc, setImageSrc] = useState(require('@/images/groupMessage/upload.png'))
  const [fileUrl, setFileUrl] = useState()

  useEffect(() => {
    if(!file) return

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function(e) {
      // console.log(e.target.result)
      setImageSrc(e.target.result)
    }
  }, [file])

  const onInputChange = useCallback((e) => {
    const file = e.target.files[0]
    setFile(file)
  }, [])

  const [visible, setVisible] = useState(false)

  const next = useCallback(() => {
    if(!file){
      Toast.info('请选择图片', 2, null, false)
      return
    }

    Toast.loading('图片上传中', 0, null, true)
    upload(file)
    .finally(() => Toast.hide())
    .then(res => {
      setFileUrl(res.url)
      setVisible(true)
    })
    .catch(() => Toast.info('上传失败，请重试', 2, null, false))
  }, [file])

  const send = useCallback(({ type, value: val, title }) => ({
    promise: sendGroupMessage({
      type: 1,
      body: fileUrl,
      mass_type: type,
      mass_value: val,
      extra: title
    }),
    success: res => goBack()
  }), [fileUrl])

  return (
    <div className={styles.container}>
      <Header title="发送图片" onGoBack={goBack} />
      <div className={styles.title}>添加图片</div>
      <div className={styles.upload}>
        <input type='file' ref={fileRef} onChange={onInputChange} />
        <img onClick={selectFile} src={imageSrc} />
      </div>
      <div className={styles['next-step_wrapper']}>
        <button className={styles['next-step']} onClick={next}>下一步</button>
      </div>
      <SendWithTarget visible={visible} type="图片" onGoBack={() => setVisible(false)} onSend={send} />
    </div>
  )
}

export default GroupMessageWithPicture
