import React, { useReducer, useState } from 'react'
import ModalMask from './Mask'
import styles from "./index.module.scss";

const EmptyComponent = () => null
// const noop = () => {}

// const configReducer = (state, action) => {
//   return state
// }

// const initialState = {
//   title: '提示',
//   content: '',
//   btns: [
//     { label: '取消', type: 'cancel' },
//     { label: '确定' },
//   ]
// }

function Confirm({ visible, onDetermine, onCancel, onClose, title, content }, ref) {
  // const [config, dispatch] = useReducer(configReducer, initialState)

  if(!visible) {
    return <EmptyComponent />
  }

  return (
    <ModalMask onClose={onClose}>
      <div className={styles.confirm}>
        <h2 className={styles.confirm_title}>{title}</h2>
        <div className={styles.confirm_content}>{content}</div>
        <div className={styles.confirm_btns}>
          <button className={styles.confirm_cancel} onClick={onCancel}>取消</button>
          <button className={styles.confirm_determine} onClick={onDetermine}>确定</button>
        </div>
      </div>
    </ModalMask>
  )
}

export default React.forwardRef(Confirm)
