import React from 'react'
import Loadable from "react-loadable"
import { ActivityIndicator } from 'antd-mobile'
import "antd-mobile/lib/activity-indicator/style/css"

const Loading = () => (
    <div className="toast-container">
      <div className="toast-example">
        <ActivityIndicator
            toast
            text="Loading..."
            animating={true}
        />
      </div>
    </div>
)

export default function(loader) {
  return Loadable({
    loader,
    loading: Loading
  })
}