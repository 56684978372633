import { router } from 'dva'

export const NavLink = router.NavLink
export const Link = router.Link
export const Prompt = router.Prompt
export const Redirect = router.Redirect
export const Router = router.Router
export const Switch = router.Switch
export const useHistory = router.useHistory
export const useLocation = router.useLocation
export const useParams = router.useParams
export const useRouteMatch = router.useRouteMatch
export const withRouter = router.withRouter
