import { isEmpty } from "../utils/util"
export const h5URL = "http://drugagent.h5.yaoxiebang.cn" // 正式版本 - .com.cn


let u = window.navigator.userAgent
let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1
let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
let isMobile  = !!u.match(/AppleWebKit.*Mobile.*/)//是否在移动端
let isInWechat  = u.match(/MicroMessenger/i) == "MicroMessenger" //判断是否在小程序内部


export const getUserUid = () => {
  let ambientName = window.localStorage.getItem("ambientName")
  let uID = null
  switch (ambientName) {
    case "IOS":
      uID = window.localStorage.getItem("iosUserUid")
      break
    case "Android":
      uID = window.localStorage.getItem("androidUserUid")
      break
    case "WeProgram":
      uID = window.localStorage.getItem("WeProgramUserUid")
      break
    default:
      break
  }
  return Number(uID)
}

export const getUserToken = () => {
  // let ambientName = window.localStorage.getItem("ambientName")
  // switch (ambientName) {
  //   case "IOS":
  //     return window.localStorage.getItem("iosUserToken")
  //   case "Android":
  //     return window.localStorage.getItem("androidUserToken")
  //   case "WeProgram":
  //     return window.localStorage.getItem("WeProgramUserToken")
  //   default:
  //     break
  // }
  return window.localStorage.getItem("yxb_Token")
}

export const setProductId = id => {
  window.sessionStorage.setItem("productId", id)
}

export const setAgentId = id => {
  window.sessionStorage.setItem("agentId", id)
}

export const setAndroidValue = () => {
  let ambientName = window.localStorage.getItem("ambientName")
  switch (ambientName) {
    case "IOS":
       if(isMobile){
      //不与原生app交互

      }else{
        // IOS 注册
      const setupWebViewJavascriptBridge = callback => {
        if (window.WebViewJavascriptBridge) {
          return callback(window.WebViewJavascriptBridge)
        }
        if (window.WVJBCallbacks) {
          return window.WVJBCallbacks.push(callback)
        }
        window.WVJBCallbacks = [callback]
        var WVJBIframe = document.createElement("iframe")
        WVJBIframe.style.display = "none"
        WVJBIframe.src = "https://__bridge_loaded__"
        document.documentElement.appendChild(WVJBIframe)
        setTimeout(function() {
          document.documentElement.removeChild(WVJBIframe)
        }, 0)
      }
      setupWebViewJavascriptBridge(function(bridge) {
        bridge.callHandler("getToken", function(response) {
          let token = JSON.parse(response).token
          window.localStorage.setItem("iosUserToken", token)
        })
      })
      setupWebViewJavascriptBridge(function(bridge) {
        bridge.callHandler("getUID", function(response) {
          let uid = JSON.parse(response).uid
          window.localStorage.setItem("iosUserUid", uid)
        })
      })
      }

      break
    case "Android":
      if(isMobile){
      //不与原生app交互

      }else{
        if (
        isEmpty(window.Android.getUserToken()) &&
        isEmpty(window.android.getUserUid())
      ) {
        window.android.backLogin()
      } else {
        window.localStorage.setItem(
          "androidUserUid",
          window.android.getUserUid()
        )
        window.localStorage.setItem(
          "androidUserToken",
          window.Android.getUserToken()
        )
      }
      }

      break
    case "WeProgram":
      if (window.location.hash.split("?").length > 1) {
        let token = window.location.hash.split("?")[1].split("=")[1]
        window.localStorage.setItem("WeProgramUserToken", token)
      }
      break
    default:
      break
  }
  return true
}

export const jumpChat = userPhone => {
  let ambientName = window.localStorage.getItem("ambientName")
  switch (ambientName) {
    case "IOS":
      window.webkit.messageHandlers.makeIOStimeChat.postMessage(userPhone)
      break
    case "Android":
      window.android.jumpChat(userPhone)
      break
    case "WeProgram":
      break
    default:
      break
  }
}

export const moveTo2 = amount => {
  document.documentElement.style.overflow = "auto"
  document.documentElement.scrollTop = amount
  document.body.parentNode.scrollTop = amount
  document.body.scrollTop = amount
}

export const callNum = numPhone => {
  let ambientName = window.localStorage.getItem("ambientName")
  switch (ambientName) {
    case "IOS":
      window.webkit.messageHandlers.makeIOSPhoneCall.postMessage(numPhone)
      break
    case "Android":
      window.android.callNum(numPhone)
      break
    case "WeProgram":
      break
    default:
      break
  }
}

// 后续失败后返回登录
export const backLogin = () => {
  if(!isMobile){
      window.android.backLogin()
  }else{
     window.location.href = window.document.location.protocol+ "//"+window.document.location.hostname+":"+window.document.location.port+"/#"+"/h5Login/1/130362"
  }
}

// 退出登录
export const exitLogin = () => {
  window.android.exitLogin()
}

export const shareToWX = (url, title, info, imgUrl) => {
  let ambientName = window.localStorage.getItem("ambientName")
  switch (ambientName) {
    case "IOS":
      let shareUrl = url
      let productName = title
      window.webkit.messageHandlers.shareIOS.postMessage({
        shareUrl,
        productName,
        info,
        imgUrl
      })
      break
    case "Android":
      window.android.shareToWX(url, title, info, imgUrl)
      break
    case "WeProgram":
      break
    default:
      break
  }
}

export const nativeBack = () => {
  let ambientName = window.localStorage.getItem("ambientName")
  switch (ambientName) {
    case "IOS":
      window.webkit.messageHandlers.backIOSVC.postMessage(null)
      break
    case "Android":
      window.android.leavePage()
      break
    case "WeProgram":
      break
    default:
      break
  }
}
// 首页活动跳转
export const homeActivityJump = url => {
  window.android.actionSendUrl(url)
}

// ios Android - 编辑
export const jumpToEdit = () => {
  window.android.jumpToEdit()
}

// ios Android - 新建
export const jumpToAdd = () => {
  let ambientName = window.localStorage.getItem("ambientName")
  switch (ambientName) {
    case "IOS":
      window.webkit.messageHandlers.continueAddProducts.postMessage(null)
      break
    case "Android":
      window.android.jumpToAdd()
      break
    case "WeProgram":
      break
    default:
      break
  }
}
