import React, { memo } from 'react'
import styles from './index.module.scss'

function ScrollView({ children, id, ...restProps }, ref) {
  return (
    <div {...restProps} id={styles.scrollView} ref={ref}>
      { children }
    </div>
  )
}

export default memo(React.forwardRef(ScrollView))
