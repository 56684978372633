import request from '@/utils/request2'

/**
 * 上传文件
 * @param {FormData} data 
 */
export function upload(data){
  return request({
    url: '/upload',
    method: 'post',
    data
  })
}

/**
 * 添加统计数据
 * @param {object} data
 * @param {string} data.type
 * @returns {Promise}
 */
export function census(data) {
  return request({
    url: '/log/record',
    method: 'post',
    data
  })
}